import { EnvironmentConfig } from "../app/shared/models/config/environment-config.model";

const api_key = 'UmhmT1B1VUJjNWljMks3eXF6UitFMzFxTERKUWRoam02SmlJTy80QlRzZGpVd1RUL1hhUVRTL1N6MW1EUG55U3ZHbjdCQkQ2OTE5cS84Z3h2a3I5ZXlDQXRLeHVJbmFGb1NBZUhERmVqdk09';
const key_sig = '1D73145B-A27A-4C68-A32B-D1D54D4AA05B';

export const environment: EnvironmentConfig = {
  production: false,
  contacts: [
    { key: 'b2b', phone: '1-877-876-0827' },
    { key: 'b2c', phone: '1-800-876-6827' }
  ],
  clients: [
    {
      name: 'events',
      url: 'https://ea-events.nna-platform.com',
      prefix: 'api/v1',
      authType: 'key-sig',
      authKey: api_key,
      authSecret: key_sig,
    },
    {
      name: 'orders',
      url: 'https://ea-orders.nna-platform.com',
      prefix: 'api/v1',
      authType: 'key-sig',
      authKey: api_key,
      authSecret: key_sig,
    }
  ]
}
