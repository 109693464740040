import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ProblemDetails, UserDetails } from "../models";

export const AppActions = createActionGroup({
  source: 'App',
  events: {

    'Loading': props<{ token: string }>(),
    'Loaded': props<{ userDetails: UserDetails }>(),
    'Raise Error': props<{ error: ProblemDetails, isFatal: boolean, route?: string }>(),
    'Reset': emptyProps()
  }
});
