export enum Statuses {
  Idle = 'Idle',
  Loading = 'Loading',
  LoadingSucceeded = 'LoadingSucceeded',
  LoadingFailed = 'LoadingFailed',
  Saving = 'Saving',
  SaveSucceeded = 'SavedSucceeded',
  SaveFailed = 'SaveFailed',
  Deleting = 'Deleting',
  DeleteSucceeded = 'DeleteSucceeded',
  DeleteFailed = 'DeleteFailed',
  Updating = 'Updating',
  UpdateSucceeded = 'UpdateSucceeded',
  UpdateFailed = 'UpdateFailed',
  Searching = 'Searching',
  SearchSucceeded = 'SearchSucceeded',
  SearchFailed = 'SearchFailed',
  Failure = 'Failure'
}
