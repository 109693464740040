import { createFeature, createReducer, on } from "@ngrx/store";
import { initialState, Statuses } from ".";
import { AppActions } from "./app.actions";
import { AppState } from "./app.state";

export const appFeature = createFeature({
  name: 'app',
  reducer: createReducer(
    initialState,

    on(AppActions.loading, (state): AppState => ({ ...state, status: Statuses.Loading, initialized: false })),
    on(AppActions.loaded, (state, { userDetails }): AppState => ({ ...state, status: Statuses.LoadingSucceeded, initialized: true, userDetails, error: undefined })),
    on(AppActions.raiseError, (state, { error }): AppState => ({ ...state, status: Statuses.Failure, initialized: true, error, userDetails: undefined })),
    on(AppActions.reset, (state): AppState => ({ ...state, status: Statuses.Idle, initialized: false, userDetails: undefined, error: undefined })),
  )
});

export const {
  name: appFeatureKey,
  reducer: appReducer,
  selectInitialized,
  selectUserDetails,
  selectError,
  selectStatus,
} = appFeature;
