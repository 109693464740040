import { Statuses } from ".";
import { UserDetails } from "../models";

export interface AppState {
  initialized: boolean;
  userDetails: UserDetails | undefined;
  error: any | undefined;
  status: Statuses;
}

export const initialState: AppState = {
  initialized: false,
  userDetails: undefined,
  error: undefined,
  status: Statuses.Idle
};
